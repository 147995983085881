<!--
 * @Module  详情页 通常页
 -->

<template>
  <div>
    <!-- v-if="isPc"  -->
    <div class="detail-content">
      {{ type }}
      <div v-if="type === 'pdf'">
        <div v-show="pdfTitle != ''" class="pdf-title">
          <div class="title-text">
            {{ pdfTitle }}
          </div>
        </div>
        <iframe
          v-if="type === 'pdf'"
          :src="pdfUrl"
          width="100%"
          name=""
          id="myIframe"
          scrolling="no"
          frameborder="0"
          style="margin-top: 20px"
        ></iframe>
      </div>

      <div
        v-else
        class="detail-content_main"
        v-html="content.contentDetails"
      ></div>
    </div>

    <!-- <div v-else class="detail-content-wap">
      <div class="detail-wap-content_main" v-html="content.contentDetails"></div>
    </div> -->
  </div>
</template>
<!--JavaScript-->
<script>
import { mapState } from 'vuex'
import { dateObj } from '@/utils/index'
export default {
  name: 'DetailContent',
  props: {
    content: {
      type: Object,
      default: () => ({
        contentDetails: ''
      })
    },
    type: {
      type: String,
      default: () => ''
    },
    pdfTitle: {
      type: String,
      default: () => ''
    },
    pdfUrl: {
      type: String,
      default: () => ''
    }
  },
  mounted() {
    this.changeFrameHeight()
  },
  methods: {
    /**
     * @description: 设置pdf高度
     */
    changeFrameHeight() {
      const myIframe = document.getElementById('myIframe')
      if (myIframe) {
        myIframe.height = document.documentElement.clientHeight
      }
    },
    /**
     * @description: 时间格式转换 YYYY.MM.DD
     * @param {number} time 时间戳
     */
    getDateTime(time) {
      let date = dateObj(time)

      return date.year + '.' + date.month + '.' + date.day
    }
  },
  computed: {
    ...mapState(['isPc'])
  }
}
</script>
<style lang="scss" scoped>
.detail-content {
  flex: 1;
  background: #fff;

  &_title {
    width: 100%;
    padding: 30px 0 12px;
    border-bottom: 1px dashed #ececec;
    .title-text {
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      color: #2781cf;
      margin-bottom: 12px;
    }

    .title-info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      margin-bottom: 4px;

      > div {
        margin: 0 30px;
      }
    }
  }

  &_main {
    padding: 20px;
    font-size: 12px;
    @media screen and (max-width: 820px) {
      width: 100%;
      min-height: auto;
    }
  }

  .pdf-title {
    width: 100%;
    padding: 30px 0 12px;

    .title-text {
      text-align: center;
      font-size: 21px;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
    }
  }
}

.detail-content-wap {
  background: #fff;

  >>> img,
  >>> video {
    max-width: 100% !important;
  }

  >>> * {
    font-size: 0.14rem !important;
  }

  &_main {
    font-size: 0.14rem;
  }
}
</style>
