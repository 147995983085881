<!--
 * @Module  上下篇
 -->

<template>
  <div>
    <!-- PC端展示内容 -->
    <div v-if="isPc"  class="previous-next">
      <div v-if="previousItem || nextItem" class="previous-next_box">
        <div
          v-if="previousItem"
          class="item_box"
          @click="handleRowDetail(previousItem)"
        >
          【上一篇】 {{ previousItem.contentTitle }}
        </div>

        <div
          v-if="nextItem"
          class="item_box"
          @click="handleRowDetail(nextItem)"
        >
          【下一篇】 {{ nextItem.contentTitle }}
        </div>
      </div>
    </div>

    <!-- wap端展示内容 -->
    <div v-else class="previous-next-wap">
      <div v-if="previousItem || nextItem" class="wap-next_box">
        <div
          v-if="previousItem"
          class="item_box"
          @click="handleRowDetail(previousItem)"
        >
          上一篇： <span>{{ previousItem.contentTitle }}</span>
        </div>

        <div
          v-if="nextItem"
          class="item_box"
          @click="handleRowDetail(nextItem)"
        >
          下一篇： <span>{{ nextItem.contentTitle }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<!--JavaScript-->
<script>
import { mapState } from 'vuex'
export default {
  name: 'PreviousNext',
  props: {
    content: {
      type: Object
    }
  },
  data() {
    return {
      previousItem: null,
      nextItem: null
    }
  },
  computed: {
    ...mapState(['isPc'])
  },
  watch: {
    content: {
      handler(newVal, oldVal) {
        if (newVal) {
          // 获取所在栏目全部文章列表
          this.getAllListData()
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    /**
     * @description: 获取所在栏目全部文章列表
     */
    async getAllListData() {
      try {
        if (!this.content.categoryId) {
          console.log('id is null')
          return
        }
        const { errCode, data } = await this.api.post(
          '/webclient/allContentList',
          {
            categoryId: this.content.categoryId,
            contentDisplay: false
          }
        )
        if (!errCode) {
          // 先id倒序
          data.sort(function (a, b) {
            return a.id - b.id
          })

          // 发布时间倒序
          data.sort(function (a, b) {
            return b.contentTime - a.contentTime
          })

          let currentIndex = data.findIndex((item) => {
            return item.id === this.content.id
          })

          this.previousItem = data[currentIndex - 1] || null

          this.nextItem = data[currentIndex + 1] || null
        } else {
          this.previousItem = null
          this.nextItem = null
        }
      } catch (error) {
        console.log(error)
      }
    },
    /**
     * 子内容列表点击
     */
    handleRowDetail(row) {
      const { contentUrl, id = null } = row

      if (contentUrl) {
        // 外链
        window.open(contentUrl)
      } else {
        // 内链
        this.naviGate('detail', { t: new Date().getTime(), contentId: id })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.previous-next {
  width: 100%;

  &_box {
    width: 100%;
    border-top: 1px solid #ececec;
    margin-top: 24px;
    .item_box {
      font-size: 14px;
      color: #333333;
      line-height: 14px;
      margin-top: 24px;
      cursor: pointer;

      &:hover {
        color: #409eff;
      }
    }
  }
}

.previous-next-wap {
  .wap-next_box {
    padding: 0.12rem 0.2rem 0.2rem;
    border-top: 1px solid #E8E8E8;
    .item_box {
      font-size: 0.14rem;
      margin-top: 0.08rem;
      color: #66748D;
      span {
        color: #0E59AE !important;
      }
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
