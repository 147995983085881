<!--
 * @Module  详情页
 -->

<template>
  <div>
    <!-- PC端展示内容 -->
    <!-- v-if="isPc" -->
    <div class="detail" :class="isPc ? '' : 'wap_img'">
      <template>
        <!-- <img v-if="categoryImg" class="detail_banner" :src="handleUrl(categoryImg)" alt="" /> -->
        <!-- <img v-else class="detail_banner" src="~@/assets/images/pc/banner_subject.png" alt="" /> -->
      </template>

      <div class="detail_main">
        <div class="detail_main-wrap">
          <!-- 左侧菜单栏目 -->
          <LeftMenu
            v-if="isPc"
            style="margin-top: 100px"
            :categoryTitle="categoryTitle"
            :subMenuList="subMenuList"
            @handleLeftMenu="handleCategoryList"
          ></LeftMenu>
          <!-- 内容 -->
          <div class="detail_main-wrap-content">
            <div class="location">
              <div v-if="isPc" class="location_title">{{ subTitle }}</div>
              <div class="location_link">
                <navber
                  :data="navberList"
                  @navbarChange="navbarChange"
                ></navber>
              </div>
            </div>

            <components
              :is="cutTab"
              :content="content"
              :type="type"
              :pdfTitle="pdfTitle"
              :pdfUrl="pdfUrl"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- wap端展示内容 -->
    <!-- <div v-else class="detail-wap"></div> -->
  </div>
</template>
<!--JavaScript-->
<script>
import { mapState, mapGetters } from 'vuex'
import base from '@/utils/base'
import LeftMenu from '@/components/left-menu/index'
import Navber from '@/components/navber/navber'
import detail from './diy/detail/detail' // 详情页 通常页
import DetailContent from './diy/detail/detailContent' // 详情页 仅展示详情内容
import { getTreeMenu, convertImgUrl } from '@/utils/index'
export default {
  name: 'MachineDetail',
  mixins: [base],
  data() {
    return {
      type: '',
      pdfTitle: '',
      pdfUrl: '',
      cutTab: '',
      categoryImg: '',
      navberList: [], // 导航数据
      subMenuList: [], // 左侧子菜单数据
      categoryTitle: '', // 子菜单标题
      subTitle: '', // 子内容标题
      content: { contentDetails: '' },
      contentId: null
    }
  },
  created() {
    // 赋值contentId
    const { query } = this.$route
    this.contentId = parseInt(query.contentId) ? parseInt(query.contentId) : ''
    this.init()
  },
  methods: {
    async init() {
      if (!this.categoryList) {
        try {
          const { errCode, data } = await this.api.post(
            '/webclient/getSiteCategory',
            {
              siteId: this.$siteId
            }
          )
          if (!errCode) {
            // 排序树结构数据
            data.sort(function (a, b) {
              return a.categorySort - b.categorySort
            })

            // 菜单栏目（isMenu为 1显示）
            const filterData = (data || []).filter((item) => item.isMenu)
            this.$store.commit('setCategoryList', filterData)

            this.getData()
          }
        } catch (error) {
          console.log('getSiteCategory：异常')
        }
      } else {
        this.getData()
      }
    },
    /**
     * 获取内容详情
     */
    async getData() {
      try {
        if (!this.contentId) {
          console.log('contentId is null')
          return
        }
        const { errCode, data } = await this.api.post('/webclient/getContent', {
          contentId: this.contentId
        })
        if (!errCode) {
          this.content = data

          this.$root.eventBus.$emit('categoryId', {
            categoryId: data.categoryId
          })

          // 梳理整合数据
          this.combination(data)

          // 处理关键字
          this.handleKeyword()
        } else {
          this.content = { contentDetails: '' }
        }
      } catch (error) {
        console.log(error)
      }
    },
    /**
     * 梳理整合数据
     * @param {data} 内容详情
     */
    combination(data) {
      const { categoryId, id } = data

      // 获取详情页的父级菜单的详情数据
      const upLevelMenu = this.categoryList
        .filter((item) => item.id === categoryId)
        .pop()

      if (!upLevelMenu) {
        this.naviGate('index', { t: new Date().getTime() }) // 首页id:0(特殊)
        return
      }

      // 根据配置的栏目下属内容页url 展示不同组件
      this.cutTab =
        upLevelMenu.categoryUrl && upLevelMenu.categoryUrl !== ''
          ? upLevelMenu.categoryUrl
          : 'detail'

      if (!upLevelMenu.categoryId || upLevelMenu.categoryId === '') {
        // 代表为一级菜单直接点击获得数据 (无子菜单的一级菜单)
        this.navberList = [
          {
            level: 1,
            categoryId: '',
            id: upLevelMenu.id,
            categoryTitle: upLevelMenu.categoryTitle,
            isLeaf: upLevelMenu.isLeaf
          },
          {
            level: 2,
            categoryId: categoryId,
            id: id,
            categoryTitle: '文章正文',
            // categoryTitle: contentTitle,
            isLeaf: null
          }
        ] // 赋值导航菜单
        this.subMenuList = [] // 赋值左侧子菜单数据
        this.categoryImg = upLevelMenu.categoryImg
        this.categoryTitle = upLevelMenu.categoryTitle // 赋值子菜单标题
        this.subTitle = upLevelMenu.categoryTitle // 赋值子内容标题
      } else {
        // 代表为点击二级菜单获得数据 (有子菜单的一级菜单 点击等同二级菜单第一个菜单点击)

        // 通过子节点id获取该树分支中的所有父节点数据
        let treeMenu = getTreeMenu(this.menuList, upLevelMenu.id) || []

        // 取第一层的父结构(包含所有子节点的树结构)
        let manyMenu = treeMenu
          .filter((item) => {
            return !item.categoryId
          })
          .pop()

        // 赋值导航菜单
        this.navberList =
          this.cutTab === 'detailContact'
            ? [
                {
                  level: 1,
                  categoryId: '',
                  id: manyMenu.id,
                  categoryTitle: manyMenu.categoryTitle,
                  isLeaf: manyMenu.isLeaf
                },
                {
                  level: 2,
                  categoryId: upLevelMenu.categoryId,
                  id: upLevelMenu.id,
                  categoryTitle: upLevelMenu.categoryTitle,
                  isLeaf: upLevelMenu.isLeaf
                }
              ]
            : [
                {
                  level: 1,
                  categoryId: '',
                  id: manyMenu.id,
                  categoryTitle: manyMenu.categoryTitle,
                  isLeaf: manyMenu.isLeaf
                },
                {
                  level: 2,
                  categoryId: upLevelMenu.categoryId,
                  id: upLevelMenu.id,
                  categoryTitle: upLevelMenu.categoryTitle,
                  isLeaf: upLevelMenu.isLeaf
                },
                {
                  level: 3,
                  categoryId: categoryId,
                  id: id,
                  categoryTitle:
                    upLevelMenu.categoryListUrl === 'listVideo'
                      ? '视频资源详情'
                      : '文章正文', // 详情页为视频资源列表页打开的详情时 文章正文 改为 视频资源详情
                  // categoryTitle: contentTitle,
                  isLeaf: null
                }
              ]

        manyMenu.child.forEach((item, index) => {
          item.checked = false
          item.select = false
          if (item.id === upLevelMenu.id) {
            item.checked = true
            item.select = true
          } else {
            item.checked = false
          }

          if (item.child) {
            item.child.forEach((obj, i) => {
              if (obj.id === upLevelMenu.id) {
                obj.checked = true

                // 此项目额外选中父栏目
                item.checked = true
                item.select = true
              } else {
                obj.checked = false
              }
            })
          }
        })

        this.subMenuList = manyMenu.child // 赋值左侧子菜单数据
        this.categoryImg = manyMenu.categoryImg
        this.categoryTitle = manyMenu.categoryTitle // 赋值子菜单标题
        this.subTitle = upLevelMenu.categoryTitle // 赋值子内容标题
      }
    },
    /**
     * 处理关键字
     */
    handleKeyword() {
      let contentKeyword = this.content ? this.content.contentKeyword : ''
      if (contentKeyword) {
        // eslint-disable-next-line no-eval
        contentKeyword = eval('(' + contentKeyword + ')')
        if (contentKeyword.type) {
          this.type = contentKeyword.type

          // pdf处理-内嵌pdf
          if (this.type === 'pdf') {
            const contentDetails = this.content.contentDetails
            if (contentDetails) {
              const pdfUrl = this.handleUrl(
                contentDetails.match(/href="(\S*).pdf"/)[1]
              )

              this.pdfUrl =
                './static/pdf/web/viewer.html?file=' +
                pdfUrl +
                '.pdf' +
                '?r=' +
                new Date().getTime()
            }

            if (contentKeyword.title) {
              this.pdfTitle = contentKeyword.title
            }
          }
        }
      } else {
        this.type = ''
      }
    },
    /**
     * @description: 拼接图片地址
     * @param {string} url 图片地址
     */
    handleUrl(url) {
      return convertImgUrl(url)
    },
    loadStyle() {
      // if (this.isPc) {
      //   require('@/assets/module/pc/detail.scss')
      // } else {
      //   require('@/assets/module/wap/detail.scss')
      // }
    }
  },
  computed: {
    ...mapState(['categoryList', 'isPc']),
    ...mapGetters(['menuList'])
  },
  watch: {
    isPc: {
      handler: 'loadStyle',
      immediate: true
    },
    $route(to, from) {
      // 赋值contentId
      const { query } = to
      this.contentId = parseInt(query.contentId)
        ? parseInt(query.contentId)
        : ''

      // 获取内容详情
      this.getData()
    }
  },
  components: { LeftMenu, Navber, detail, DetailContent }
}
</script>

<style lang="scss" scoped>
/* 详情页 pc端 */
.detail {
  width: 100%;
  @media screen and (max-width: 820px) {
    overflow-x: hidden;
  }
  &_banner {
    width: 100%;
    height: 15.625vw;
  }

  &_main {
    width: 100%;
    min-height: 800px;
    background: #fff;
      @media screen and (max-width: 820px) {
        min-height: auto;

      }
    &-wrap {
      display: flex;
      width: 1280px;
      height: auto;
      margin: 0 auto 12px;
      background: #fff;
      @media screen and (max-width: 820px) {
        width: 100%;
        margin: 0 auto 0.12rem;
      }
      &-content {
        width: 0;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        margin-left: 40px;
        @media screen and (max-width: 820px) {
          margin-left: 0;
        }
        .location {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #ecedf2;
          height: 50px;
          margin-top: 20px;
          @media screen and (max-width: 820px) {
            margin: 0;
            width: 100%;
            background: #f4faff;
            border: none;
            padding: 0 0.2rem;
            height: auto;
          }
          &_title {
            position: relative;
            color: #333;
            font-size: 22px;
            font-weight: bold;
            @media screen and (max-width: 820px) {
              font-size: 0.18rem;
              line-height: 0.32rem;
            }
            &::before {
              position: absolute;
              left: 0;
              bottom: -11px;
              content: '';
              width: 100%;
              height: 2px;
              background: #0e59ae;
            }
          }
          &_link {
            @media screen and (max-width: 820px) {
              padding: 0.08rem 0;
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.navber-wap {
  border: none !important;
  padding: 0 !important;
}
.wap_img img {
  width: 100% !important;
  height: auto !important;
  object-fit: contain !important;
}
</style>
