<!--
 * @Module  详情页 通常页
 -->

<template>
  <!-- <div> -->
    <!-- v-if="isPc" -->
    <div class="detail-content">
      <div class="detail-content_title">
        <div class="title-text">{{ content ? content.contentTitle : '' }}</div>
        <div v-if="isPc" class="title-info">
          <div>
            出处:
            {{
              content && content.contentSource ? content.contentSource : '未知'
            }}
          </div>
          <div>发布时间: {{ getDateTime(content.contentTime) }}</div>
          <div>点击数: {{ content ? content.contentHit : '' }}次</div>
        </div>
        <div v-else class="title-info">
          <div>发布时间: {{ getDateTime(content.contentTime) }}</div>
        </div>
      </div>

      <div v-if="type === 'pdf'">
        <div v-show="pdfTitle != ''" class="pdf-title">
          <div class="title-text">
            {{ pdfTitle }}
          </div>
        </div>
        <iframe
          v-if="type === 'pdf'"
          :src="pdfUrl"
          width="100%"
          name=""
          id="myIframe"
          scrolling="no"
          frameborder="0"
          style="margin-top: 20px"
        ></iframe>
      </div>

      <div
        v-else
        class="detail-content_main"
        v-html="content.contentDetails"
      ></div>

      <PreviousNext :content="content"></PreviousNext>
    </div>

    <!-- <div v-else class="detail-content-wap">
      <div class="detail-wap-content_main" v-html="content.contentDetails"></div>
    </div> -->
  <!-- </div> -->
</template>

<!--JavaScript-->
<script>
import { mapState } from 'vuex'
import PreviousNext from '@/components/previous-next/index' // 上一篇下一篇
import { dateObj } from '@/utils/index'
export default {
  name: 'detail',
  props: {
    content: {
      type: Object,
      default: () => ({
        contentDetails: ''
      })
    },
    type: {
      type: String,
      default: () => ''
    },
    pdfTitle: {
      type: String,
      default: () => ''
    },
    pdfUrl: {
      type: String,
      default: () => ''
    }
  },
  mounted() {},
  methods: {
    /**
     * @description: 设置pdf高度
     */
    changeFrameHeight() {
      const myIframe = document.getElementById('myIframe')

      if (myIframe) {
        myIframe.height = document.documentElement.clientHeight
      }
    },
    /**
     * @description: 时间格式转换 YYYY.MM.DD
     * @param {number} time 时间戳
     */
    getDateTime(time) {
      let date = dateObj(time)

      return date.year + '.' + date.month + '.' + date.day
    }
  },
  watch: {
    content: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.$nextTick(() => {
            // 设置pdf高度
            this.changeFrameHeight()
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  components: { PreviousNext },
  computed: {
    ...mapState(['isPc'])
  }
}
</script>
<style lang="scss" scoped>
.detail-content {
  flex: 1;
  background: #fff;

  ::deep * {
    word-wrap: break-all !important;
  }

  &_title {
    width: 100%;
    padding: 30px 0 12px;
    border-bottom: 1px dashed #ececec;
    @media screen and (max-width: 820px) {
      border: none;
      padding: 0.21rem 0 0;
    }
    .title-text {
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      color: #2781cf;
      margin-bottom: 12px;
      @media screen and (max-width: 820px) {
        font-size: 0.18rem;
        color: #333333;
        line-height: 0.32rem;
        padding: 0 0.2rem;
      }
    }

    .title-info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      margin-bottom: 4px;
      @media screen and (max-width: 820px) {
        font-size: 0.14rem;
        color: #999999;
        margin: 0.2rem 0 0;
      }
      > div {
        margin: 0 30px;
      }
    }
  }

  &_main {
    padding: 20px 20px 32px;
    font-size: 12px;
    @media screen and (max-width: 820px) {
      font-size: 0.14rem;
      padding: 0.2rem 0.2rem 0.32rem;
    }
  }

  .pdf-title {
    width: 100%;
    padding: 30px 0 12px;

    .title-text {
      text-align: center;
      font-size: 21px;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
    }
  }
}

.detail-content-wap {
  background: #fff;
  height: 100%;
  ::deep * {
    font-size: 0.14rem;
    word-wrap: break-all !important;
  }

  >>> img,
  >>> video {
    max-width: 100% !important;

  }

  &_main {
    font-size: 0.14rem;
  }
}
</style>
